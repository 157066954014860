// Flex CTA's below homepage video

@include breakpoint("md"){
  .globalCTAs__cta{
    padding-right: 2em;
  }
}

.pane-viu-flex-ctas{
  .globalCTAs__cta {
    text-align: left;
  }
}
