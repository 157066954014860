// Specific styles for VIU program pages
// --------------------------------------------------

@import "../../../../../../profiles/viu/themes/viu_theme/scss/base/mixins";

.program-header{
  ul{
    margin-left:0;
  }
  li{
    margin-bottom: 1em;
    @include make-sm-column(6);
    @include make-md-column(3);
  }
  .field-item{
    font-weight: bold;
    color: $brand-primary;
  }
}
.field-label:before{
  font-family: FontAwesome;
  margin-right: .5em;
}
.field-program-length .field-label:before{
  content:"\f133";
}
.field-credential .field-label:before{
  content:"\f19d";
}
.field-program-options .field-label:before{
  content:"\f14a";
}
.field-campus .field-label:before{
  content:"\f041";
}

.field-program-website a{
  @include cta-button;
}

.program-website-links{
  clear:both;
  .field, .cta-button{
    display: inline-block;
    vertical-align: top;
    margin-right: .5em;
  }
}