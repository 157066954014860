/*.webform-component--i-would-like-more-information-on{
  background-color:#ededed;
  border: 1px solid #cccccc;
  padding:2em 2em;
  margin-bottom:2em;
  overflow:hidden;
  border-radius: 5px;
}

.webform-component--i-would-like-more-information-on > label{
  font-size: 20px;
  font-weight:normal;
  display: block;
}
*/
.webform-component--i-would-like-more-information-on .radio + .radio, .webform-component--i-would-like-more-information-on .checkbox + .checkbox {
  margin-top: 0.4em;
}

#webform-client-form-3228 h4{
  margin-top: 0;
  padding-top: 1.5em;
  clear:left;
}

@media (min-width: 720px){
/*
  .webform-component--i-would-like-more-information-on{
    padding:2em 4em;
  }

  .webform-component--i-would-like-more-information-on > label{
    font-size: 24px;
    text-align: center;
    font-weight:normal;
    display: block;
  }

  .webform-component--i-would-like-more-information-on .form-item{
    float:left;
    width:33%;
  }
*/
  #webform-client-form-3228 .webform-component--first-name{
    float:left;
    width:calc(50% - 10px);
    margin-right: 20px;
  }
  #webform-client-form-3228 .webform-component--last-name{
    float:left;
    width:calc(50% - 10px);
  }

  #webform-client-form-3228 .webform-component--have-you-been-issued-a-viu-student-number{
    float:left;
    width:calc(60% - 10px);
    margin-right: 20px;
    margin-top:1em;
  }
  #edit-submitted-have-you-been-issued-a-viu-student-number{
    display: inline-block;
  }
  #webform-client-form-3228 .webform-component--student-number{
    width:calc(40% - 10px);
  }
}

@media (min-width: 1400px) {
  #webform-client-form-3228 .webform-component--student-number {
    float:left;
  }
}

/* Hide referrer field */
.webform-component--referrer{
  display: none;
}
