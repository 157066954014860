// Mixins
// -----------------------------------------------------------------------------

// Clearfix - DO NOT REMOVE
// Includes the has-layout part from compass without the overflow hidden
// and the pseudo classes from bootstrap, otherwise all drop menus get clipped.
@mixin clearfix {
  @include has-layout;
  &:before,
  &:after {
    content: " "; // 1
    display: table; // 2
  }
  &:after {
    clear: both;
  }
}

// Vertical center
@mixin vertical-center{
  display: flex;
  align-items: center;
}


// CTA link
@mixin cta-button{
  // Work around for block item not full width
  display: table;
  margin: 1.5em 0;
  padding:0.6em 1em;
  // Work around for pill shape
  border-radius: 500px;
  border: 1px solid;
  line-height: 1.2em;
  text-align: center;
  &:hover,&:focus{
    text-decoration: none;
  }
}

// Mixin to provide padding and width restrictions to main nav content
// Required to selectively leave some things full width (divider lines)
@mixin nav_contained{
  margin-left:auto;
  margin-right:auto;
  width:82%;
  max-width: 400px;
  @include breakpoint("sm"){
    max-width: 470px;
  }
}


/**
 * Fix for vw, vh, vmin, vmax on iOS 7.
 * http://caniuse.com/#feat=viewport-units
 *
 * This fix works by replacing viewport units with px values on known screen sizes.
 *
 * iPhone 6 and 6 Plus cannot run iOS 7, so are not targeted by this fix.
 * Target devices running iOS 8+ will incidentally execute the media query,
 * but this will still produce the expected result; so this is not a problem.
 *
 * As an example, replace:
 *
 *   height: 50vh;
 *   font-size: 5vmin;
 *
 * with:
 *
 *   @include viewport-unit(height, 50vh);
 *   @include viewport-unit(font-size, 5vmin);
 */
@mixin viewport-unit($property, $value) {
  #{$property}: $value;

  $unit: unit($value);

  @if (index((vw, vh, vmin, vmax), $unit) != null) {
    $devices: (
                    (768px, 1024px), // iPad (all versions)
                    (320px, 480px),  // iPhone 4
                    (320px, 568px)   // iPhone 5, 5C, 5S
    );

    @each $device in $devices {
      $device-width: nth($device, 1);
      $device-height: nth($device, 2);

      $device-query: "only screen and (-webkit-min-device-pixel-ratio: 1)";
      $device-query: "#{$device-query} and (device-width: #{$device-width})";
      $device-query: "#{$device-query} and (device-height: #{$device-height})";

      $percent: $value / ($value * 0 + 1); // see https://github.com/sass/sass/issues/533

      $percent-width: $device-width * $percent / 100;
      $percent-height: $device-height * $percent / 100;

      @if ($unit == vmin or $unit == vmax) {
        @media #{$device-query} {
          #{$property}: if($unit == vmin, $percent-width, $percent-height);
        }
      }
      @else {
        @media #{$device-query} and (orientation: portrait) {
          #{$property}: if($unit == vw, $percent-width, $percent-height);
        }

        @media #{$device-query} and (orientation: landscape) {
          #{$property}: if($unit == vw, $percent-height, $percent-width);
        }
      }
    }
  }
}