// Styles for Forms on VIU Home site
// --------------------------------------------------

.views-widget-filter-field_program_area_search{
  @include make-xs-column(12);
  @include make-sm-column(6);
}
.views-widget-filter-field_campus{
  @include make-xs-column(12);
  @include make-sm-column(6);
}
.views-widget-filter-field_credential{
  @include make-xs-column(12);
}

.view-program-search{
  .views-reset-button, .views-submit-button {
    float: right;
    padding: .5em .5em 0 0;
  }
}
