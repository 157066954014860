// Homepage fact block

.viuFact__heading{
  text-transform: none;
  font-weight:bold;
  font-size: 1.75em;
  margin-bottom: 0em;
}

.viuFact__subtext{
  color: $brand-primary;
  margin-bottom: 1em;
}